import { XClose } from "@beije/react-icons";
import {
  Box,
  Modal as MuiModal,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PropsWithChildren } from "react";

import { ModalWrapperSx, TitleWrapperSx } from "./styles";

type ModalProps = {
  open: boolean;
  title: string;
  subtitle?: string | JSX.Element;
  onClose: () => void;
  children?: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  top?: string;
};

const Modal = ({
  open,
  title,
  subtitle,
  onClose,
  children,
  minWidth,
  maxWidth,
  top,
}: PropsWithChildren<ModalProps>) => {
  const myTheme = useTheme();
  const mediumBreakpoint = useMediaQuery(myTheme.breakpoints.down("md"));

  return (
    <MuiModal open={open} onClose={onClose} disableAutoFocus>
      <Box sx={ModalWrapperSx} style={{ minWidth, maxWidth, top }}>
        <Box sx={TitleWrapperSx}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              component={mediumBreakpoint ? "h2" : "h4"}
              variant={mediumBreakpoint ? "mobileH2" : "desktopH4"}
            >
              {title}
            </Typography>

            <IconButton sx={{ p: 0 }} onClick={onClose}>
              <XClose
                strokeWidth={1}
                width={mediumBreakpoint ? "24px" : "32px"}
                height={mediumBreakpoint ? "24px" : "32px"}
              />
            </IconButton>
          </Box>

          {typeof subtitle === "string" ? (
            <Typography variant="desktopBody1" color="#00000099">
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>

        {/* Dynamic Content */}
        {children}
      </Box>
    </MuiModal>
  );
};

export default Modal;
