import {
  Modal,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Container,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";
import BankCard, { Focused } from "react-credit-cards";
import { useForm, Controller, useWatch, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "src/store";
import {
  useCreateCreditCardMutation,
  useCreateCreditCardWith3DSecureMutation,
} from "src/store/apis";
import {
  open,
  setTransactionResponse,
  setTransactionData,
} from "src/store/slices";
import { createSnackbarErrorHandler, formatExpirationDate } from "src/utils";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type CreditCardFormData = {
  number: string;
  name: string;
  expiry: string;
  cvc: string;
  is3D: boolean;
};
// TODO: This component its looks like ModalForm component in Cards page. we can merge together.
const CreditCardFormModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [focused, setFocused] = useState<Focused | undefined>(undefined);

  const { isOpen: isTransactionModalOpen } = useSelector(
    (state: RootState) => state.TransactionModalSlice
  );

  const { t } = useTranslation();

  const [createCreditCard] = useCreateCreditCardMutation();
  const [createCreditCardWith3DSecure] =
    useCreateCreditCardWith3DSecureMutation();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
  } = useForm<CreditCardFormData>();

  // Form watchers ( we need that for 2-way data binding )
  const cardNumber = useWatch({
    control,
    name: "number",
  });

  const cardName = useWatch({
    control,
    name: "name",
  });

  const cardExpiry = useWatch({
    control,
    name: "expiry",
  });

  const cardCvc = useWatch({
    control,
    name: "cvc",
  });

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused(e.target.name as Focused);
  };

  const handleClose = () => {
    clearErrors();
    onClose();
    reset();
  };

  const createCard = (data: FixMyType, is3D: FixMyType) => {
    if (is3D) {
      createCreditCardWith3DSecure(data)
        .unwrap()
        .then((res) => {
          dispatch(setTransactionData({ key: "status", value: "WAITING" }));
          dispatch(
            setTransactionData({
              key: "transactionCheckType",
              value: "CREATE",
            })
          );
          dispatch(setTransactionResponse(res));
          handleClose();
        })
        .catch(createSnackbarErrorHandler(dispatch));
    } else {
      delete data.cvc;
      createCreditCard(data)
        .unwrap()
        .then(() => {
          handleClose();
        })
        .catch(createSnackbarErrorHandler(dispatch));
    }
  };

  const handleFormSubmit: SubmitHandler<CreditCardFormData> = (formData) => {
    formData.expiry = formatExpirationDate(formData.expiry);
    const data = {
      cardholderName: formData.name,
      number: formData.number,
      expirationDate: {
        month: formData.expiry.split("/")[0],
        year: `20${formData.expiry.split("/")[1]}`,
      },
      cvc: formData.cvc,
    };

    createCard(data, formData.is3D);

    if (formData.is3D) {
      dispatch(open());
    }
  };

  useEffect(() => {
    if (!isTransactionModalOpen) {
      reset();
      if (isOpen) {
        handleClose();
      }
    }
  }, [isTransactionModalOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => handleClose()}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "white",
          padding: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BankCard
            cvc={cardCvc || ""}
            expiry={cardExpiry || ""}
            name={cardName || ""}
            number={cardNumber || ""}
            focused={focused}
          />
        </Box>
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={3}
          onSubmit={handleSubmit(handleFormSubmit)}
          my={3}
        >
          <Controller
            rules={{
              required: t("panel:card-number.required"),
            }}
            control={control}
            name="number"
            render={({ field: { value, onChange, ref } }) => (
              <FormControl>
                <InputLabel required htmlFor="number-field-label">
                  {t("panel:card-number.label")}
                </InputLabel>

                <OutlinedInput
                  name="number"
                  id="number-field-label"
                  label={t("panel:card-number.label")}
                  fullWidth
                  onChange={onChange}
                  ref={ref}
                  value={value || ""}
                  onFocus={handleFocus}
                  error={!!errors.number}
                  inputProps={{
                    maxLength: 16,
                  }}
                />
                {!!errors.number && (
                  <FormHelperText error>
                    {errors.number?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Controller
            rules={{
              required: t("panel:card-name.required"),
            }}
            control={control}
            name="name"
            render={({ field: { value, onChange, ref } }) => (
              <FormControl>
                <InputLabel required htmlFor="name-field-label">
                  {t("panel:card-name.label")}
                </InputLabel>

                <OutlinedInput
                  name="name"
                  ref={ref}
                  id="name-field-label"
                  onChange={onChange}
                  label={t("panel:card-name.label")}
                  fullWidth
                  value={value || ""}
                  onFocus={handleFocus}
                  error={!!errors.name}
                />
                {!!errors.name && (
                  <FormHelperText error>{errors.name?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Box display="flex" gap={1}>
            <Controller
              rules={{
                required: t("panel:card-expire.required"),
              }}
              control={control}
              name="expiry"
              render={({ field: { value, onChange, ref } }) => (
                <FormControl fullWidth>
                  <InputLabel required htmlFor="expiry-field-label">
                    {t("panel:card-expire.label")}
                  </InputLabel>
                  <OutlinedInput
                    name="expiry"
                    ref={ref}
                    onChange={onChange}
                    label={t("panel:card-expire.label")}
                    fullWidth
                    value={formatExpirationDate(value || "")}
                    onFocus={handleFocus}
                    error={!!errors.expiry}
                    inputProps={{ maxLength: 5 }}
                  />
                  {!!errors.expiry && (
                    <FormHelperText error>
                      {errors.expiry?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Controller
              rules={{
                required: t("panel:card-cvc.required"),
              }}
              control={control}
              name="cvc"
              render={({ field: { value, onChange, ref } }) => (
                <FormControl fullWidth>
                  <InputLabel required htmlFor="cvc-field-label">
                    {t("panel:card-cvc.label")}
                  </InputLabel>

                  <OutlinedInput
                    name="cvc"
                    onChange={onChange}
                    label={t("panel:cvc.label")}
                    fullWidth
                    ref={ref}
                    value={value || ""}
                    onFocus={handleFocus}
                    error={!!errors.cvc}
                    inputProps={{ maxLength: 4, minLength: 3 }}
                  />
                  {!!errors.cvc && (
                    <FormHelperText error>{errors.cvc?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  defaultValue={false}
                  control={control}
                  name="is3D"
                  render={({ field: { value, onChange, ref } }) => (
                    <Checkbox
                      name="is3D"
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
              }
              label={t("panel:3d-secure")}
            />
          </FormGroup>
          <Button type="submit" fullWidth variant="contained">
            {t("panel:save-card")}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
export default CreditCardFormModal;
