import { Box, Tabs, Tab } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { MenuItems } from "src/constant";

import { SectionSx, TabsSx, TabSx, WrapperSx } from "./styles";

type MobileSidebarProps = {
  menuItems: MenuItems;
};

const MobileMenu = ({ menuItems }: MobileSidebarProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const activeTabNumber = (route: string) => {
    const maybeIndexFound = menuItems.findIndex((item) => item.route === route);
    if (maybeIndexFound > -1) return maybeIndexFound;

    const extractFirstPathnameFromUrl = route?.split("/").slice(0, 3).join("/");
    if (extractFirstPathnameFromUrl) {
      const item = menuItems.find(
        (item) => item.isNested && item.route === extractFirstPathnameFromUrl
      );

      if (!item) return maybeIndexFound;
      return menuItems.findIndex((x) => x.name === item?.name);
    }
  };

  const handleLogoutClick = () => {
    router.push("/auth/logout?redirect=/");
  };

  return (
    <Box width="100%">
      <Box sx={WrapperSx}>
        <Box component="section" sx={SectionSx}>
          <Tabs
            sx={TabsSx}
            variant="scrollable"
            scrollButtons={false}
            value={activeTabNumber(router.route)}
          >
            {menuItems.map((item, index) => {
              if (item?.isNested) return null;

              if (item.name !== "divider") {
                return (
                  <Tab
                    key={item.name}
                    sx={TabSx}
                    value={index}
                    label={
                      <Link href={item.route}>{t(`panel:${item.name}`)}</Link>
                    }
                  />
                );
              }
            })}
            <Tab
              sx={TabSx}
              onClick={handleLogoutClick}
              label={t(`panel:log-out`)}
            />
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileMenu;
