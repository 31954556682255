import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

export const WrapperSx = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

export const SectionSx: SxProps = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: 3.375,
  overflowX: "scroll",
};

export const TabsSx: SxProps = {
  width: "100%",
  display: "flex",
  "	.MuiTabs-flexContainer": {
    gap: 1,
  },
};

export const TabSx: SxProps<Theme> = (theme: Theme) => ({
  padding: 0,
  // paddingY: "11px",
  textTransform: "capitalize",
  " .MuiTab-textColorPrimary": {
    padding: theme.spacing("11px", 1),
  },
});
