import { Minus, Plus } from "@beije/react-icons";
import { Box, IconButton, Typography } from "@mui/material";
import { FC } from "react";

type QuantityProps = {
  quantity: number;
  disabled?: boolean;
  onIncrease: () => void;
  onDecrease: () => void;
  max?: number;
};

const QuantitySelector: FC<QuantityProps> = ({
  quantity,
  disabled,
  onIncrease,
  onDecrease,
  max = false,
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 2,
      height: "48px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 8.25,
    }}
  >
    <IconButton
      size="large"
      onClick={onDecrease}
      disabled={quantity === 1 || disabled}
    >
      <Minus width={24} height={24} strokeWidth={1} />
    </IconButton>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "3px",
        paddingY: 1,
      }}
    >
      <Typography
        component="p"
        variant="desktopBody1"
        sx={{
          fontVariantNumeric: "tabular-nums",
          opacity: 0.6,
        }}
      >
        {quantity}
      </Typography>
    </Box>
    <IconButton
      size="large"
      onClick={onIncrease}
      disabled={disabled || !!max ? max === quantity : false}
    >
      <Plus width={24} height={24} strokeWidth={1} />
    </IconButton>
  </Box>
);

export default QuantitySelector;
