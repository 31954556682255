import { Box, Typography } from "@mui/material";
import Image from "next/image";
import LoadingGif from "public/images/loading.gif";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Image
        src={LoadingGif}
        height={300}
        alt="loader"
        unoptimized={true}
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
      <Typography component="h3" variant="desktopH1">
        {t("common:loading")}
      </Typography>
    </Box>
  );
};

export default Loading;
