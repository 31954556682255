import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

import { WrapperSx } from "./styles";

type TabsProps = {
  value: number;
  index: number;
  children: React.ReactNode;
};

const TabPanel = ({
  value,
  index,
  children,
  ...other
}: PropsWithChildren<TabsProps>) => (
  <Box
    width="100%"
    role="tabpanel"
    hidden={value !== index}
    id={`beije-tabpanel-${index}`}
    aria-labelledby={`beije-tab-${index}`}
    sx={WrapperSx}
    {...other}
  >
    {value === index && children}
  </Box>
);

export default TabPanel;
