import { Trash01, X, ChevronRight } from "@beije/react-icons";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Price } from "src/components/Common";
import { RootState } from "src/store";
import { orderSelector, setCheckoutState } from "src/store/slices";

export const PromotionCode = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { promoCodeError, promoCode: promoCodeStore } = useSelector(
    (state: RootState) => state.CheckoutSlice
  );
  const checkoutOrder = useSelector(orderSelector);

  const hasAppliedDiscount = checkoutOrder?._promoCode;

  const [showDiscount, setShowDiscount] = useState(
    !!hasAppliedDiscount || promoCodeError || false
  );
  const [promoCode, setPromoCode] = useState(promoCodeStore);

  const handlePromoCode = (state: "remove" | "add" = "add") => {
    dispatch(
      setCheckoutState({
        key: "promoCode",
        value: state === "remove" ? "" : promoCode,
      })
    );
    dispatch(
      setCheckoutState({
        key: "promoCodeError",
        value: undefined,
      })
    );
    if (state === "remove") setPromoCode("");
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Button
        variant="text"
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => setShowDiscount((_) => !_)}
        endIcon={
          <IconButton>
            {showDiscount ? (
              <X width={theme.spacing(2)} />
            ) : (
              <ChevronRight width={theme.spacing(2)} />
            )}
          </IconButton>
        }
        fullWidth
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "&:hover,:not(:hover)": {
            borderRadius: 0,
            backgroundColor: "transparent",
          },
        }}
      >
        {t("cart:promo-code")}
      </Button>
      <Collapse in={!!showDiscount}>
        <Box>
          <Box mt={1.3} display="flex" gap={3}>
            <TextField
              disabled={!!hasAppliedDiscount}
              value={promoCode}
              onChange={(event) => setPromoCode(event.target.value)}
              fullWidth
              label={t("checkout:your-code")}
              helperText={
                promoCodeError
                  ? promoCodeError[i18n.language as "tr" | "en"]
                  : undefined
              }
              error={!!promoCodeError}
            />
            <Box display="flex" flexDirection="row">
              <Button
                size="large"
                onClick={() => handlePromoCode("add")}
                variant="contained"
                disabled={!!hasAppliedDiscount}
                sx={({ spacing }) => ({ maxHeight: spacing(7) })}
              >
                {hasAppliedDiscount ? t("common:done") : t("checkout:apply")}
              </Button>
              {!!hasAppliedDiscount && (
                <IconButton
                  disableRipple
                  onClick={() => handlePromoCode("remove")}
                >
                  <Trash01 width={18} height={20} />
                </IconButton>
              )}
            </Box>
          </Box>
          {hasAppliedDiscount && (
            <Box
              sx={{
                width: "100%",
                color: "common.beije.primary.darkGreen.main",
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                py: 2,
              }}
            >
              <Typography variant="desktopBody2" component="span">
                {t("checkout:your-promo-code-applied", {
                  promoCode: promoCodeStore,
                })}
              </Typography>
              <Typography variant="desktopBody2" component="span">
                <Price value={checkoutOrder?.price?.grossPromoDiscountAmount} />
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};
