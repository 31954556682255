import { Box, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "next-i18next";

import { MENU_ITEMS } from "src/constant";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

const Sidebar = () => {
  const { t } = useTranslation();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <Box
      component="aside"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: desktop ? 1.5 : 2,
        paddingY: !desktop ? 5 : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: 1.5,
          paddingX: { lg: 0, md: 0, sm: 3.375, xs: 3.375 },
        }}
      >
        <Typography
          component="h3"
          variant={desktop ? "desktopH3" : "mobileH1"}
          sx={{
            opacity: 0.9,
            userSelect: "none",
          }}
        >
          {t("panel:my-account")}
        </Typography>
      </Box>
      {desktop ? (
        <DesktopMenu menuItems={MENU_ITEMS} />
      ) : (
        <MobileMenu menuItems={MENU_ITEMS} />
      )}
    </Box>
  );
};

export default Sidebar;
