import { Box, Button, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Image from "next/image";
import { FC, MouseEventHandler } from "react";

type BannerProps = {
  title: string;
  desc: string;
  image: string;
  imageWidth: number;
  imageHeight: number;
  onButtonClick: MouseEventHandler;
  buttonText: string;
  buttonType?: string;
};

const Banner: FC<BannerProps> = ({
  title,
  desc,
  image,
  imageWidth,
  imageHeight,
  onButtonClick,
  buttonText,
  buttonType,
}) => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: breakpoint ? "row" : "column",
      }}
    >
      <Box component="section" marginRight={breakpoint ? 14 : 0}>
        <Typography component="h2" variant="h5">
          {title}
        </Typography>

        <Typography component="p" variant="h6">
          {desc}
        </Typography>

        <Button
          fullWidth={!breakpoint}
          sx={{
            marginY: 4,
          }}
          variant="contained"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </Box>

      <Box
        sx={{
          display: breakpoint ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ebebeb",
          width: imageWidth,
          height: imageHeight,
        }}
      >
        <Image
          src={image}
          alt={desc}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Box>
    </Container>
  );
};

export default Banner;
