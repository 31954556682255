import {
  VisaLight,
  MastercardLight,
  AmexLight,
  Trash01,
  RadioButtonFill,
  RadioButtonEmpty,
} from "@beije/react-icons";
import { Card, Box, Typography, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useCallback } from "react";

type CreditCardProps = {
  card: ICard;
  onSetPrimary?: (id: string) => void;
  onDelete?: (id: string) => void;
  showRadioButton?: boolean;
};

export function CreditCard({
  card,
  onDelete,
  onSetPrimary,
  showRadioButton = false,
}: CreditCardProps) {
  const theme = useTheme();

  const handleOnDelete = useCallback(() => {
    if (!onDelete) return;

    onDelete(card._id);
  }, [card._id, onDelete]);

  const handleSetPrimary = useCallback(() => {
    if (!onSetPrimary) return;

    onSetPrimary(card._id);
  }, [card._id, onSetPrimary]);

  const renderPaymentNetworkLogo = () => {
    switch (card.paymentNetwork) {
      case "MASTERCARD":
        return <MastercardLight width={48} />;

      case "VISA":
        return <VisaLight width={48} />;

      case "AMEX":
        return <AmexLight width={48} />;

      default:
        return (
          <Typography variant="desktopSubtitle2">
            {card.paymentNetwork}
          </Typography>
        );
    }
  };

  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",

        border: onSetPrimary && card.isPrimary ? "1px solid black" : undefined,
        borderRadius: 2,
        backgroundColor: "background.white",
        width: "100%",

        [theme.breakpoints.down("md")]: {
          flex: "1 1 100%",
        },
        p: 3,
        gap: 2,
      })}
      elevation={0}
      onClick={handleSetPrimary}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "flex-start",
          gap: 3,
        }}
      >
        {showRadioButton && (
          <Box sx={{ mt: "6px", cursor: "pointer" }}>
            {card.isPrimary ? <RadioButtonFill /> : <RadioButtonEmpty />}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: 36,
            }}
          >
            <Typography component="h4" variant="mobileH4">
              {card?.name || "Card"}
            </Typography>

            {!card.isPrimary && !!onDelete && (
              <IconButton onClick={handleOnDelete}>
                <Trash01
                  stroke={theme.palette.primary.main}
                  height={20}
                  width={18}
                />
              </IconButton>
            )}
          </Box>

          <Typography variant="desktopH6" component="h3" color="text.secondary">
            {card.number}
          </Typography>

          <Typography variant="desktopBody1" color="text.primary">
            {card.cardholderName}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="desktopBody1">
              {`${card.expirationDate.month}/${card.expirationDate.year}`}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              {renderPaymentNetworkLogo()}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
