import { SxProps, Theme } from "@mui/material/styles";

export const ModalWrapperSx: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "40px 27px",
  gap: "40px",

  position: "absolute",
  left: 0,
  top: "54px",

  width: "375px",

  backgroundColor: "#F7F6F5",

  borderRadius: "0",
  marginX: {
    xs: "auto",
    md: 0,
    lg: 0,
  },
  right: 0,

  [theme.breakpoints.up("md")]: {
    left: "50%",
    top: "4rem",
    transform: "translate(-50%,0%)",
    borderRadius: "1rem !important",
    width: "564px",
    padding: "32px",
  },
});

export const TitleWrapperSx = () => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 0,
  gap: "1rem",
  width: "100%",
});
