import Image from "next/image";
import { FC } from "react";

import cardStyle from "./Card.module.scss";

type CardProps = {
  title: string;
  description: string;
  image: string;
  imageRight?: boolean;
};

const Card: FC<CardProps> = ({ title, description, image, imageRight }) => (
  <div
    className={`${cardStyle.container} ${
      imageRight ? cardStyle.rightDirection : ""
    }`}
  >
    <div className={cardStyle.image}>
      <Image
        src={image}
        alt={title}
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
    </div>

    <div className={cardStyle.content}>
      <h3 className={cardStyle.title}>{title}</h3>
      <p className={cardStyle.description}>{description}</p>
    </div>
  </div>
);

export default Card;
