import { formatPrice } from "src/utils";

interface PriceProps {
  value?: number;
  currency?: "TR";
}

export const Price = ({ value = 0, currency = "TR" }: PriceProps) => {
  return <>{formatPrice(value)}</>;
};
