/* eslint-disable import/no-named-default */
import { default as RcDrawer } from "rc-drawer";
import { FC, useEffect, useState } from "react";

import styles from "./Drawer.module.scss";

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  direction?: "left" | "right";
  isNavigation?: boolean;
  children: React.ReactNode;
};

const Drawer: FC<DrawerProps> = ({
  children,
  open,
  onClose,
  direction = "right",
  isNavigation = false,
}) => {
  const [windowWidth, setWindowWidth] = useState<null | number>(null);

  useEffect(() => {
    if (window) {
      setWindowWidth(window.innerWidth);
    }
  });

  return (
    <RcDrawer
      placement={direction}
      getContainer=""
      className={`${open && styles["drawer-active"]} ${
        direction === "left" && styles["drawer-left"]
      } ${direction === "right" && styles["drawer-right"]} ${
        isNavigation &&
        windowWidth &&
        windowWidth < 768 &&
        styles["drawer-navigation"]
      }`}
      rootClassName={styles.wrapper}
      open={open}
      onClose={onClose}
      maskStyle={{
        backgroundColor: "#000",
        opacity: 0.3,
        width: open ? "100%" : undefined,
        height: open ? "100%" : undefined,
        position: "absolute",
        left: direction === "left" ? "0" : undefined,
        right: direction === "right" ? "0" : undefined,
        top: 0,
      }}
      contentWrapperStyle={{
        display: open ? "block" : "none",
        width: windowWidth && windowWidth > 768 ? "516px" : "100%",
        position: "absolute",
        backgroundColor: "#dcdcdc",
        height: "100%",
        right: direction === "right" ? "0" : undefined,
      }}
    >
      {children}
    </RcDrawer>
  );
};

export default Drawer;
