import { FC, MouseEventHandler } from "react";

import ButtonStyle from "./Button.module.scss";

type ButtonProps = {
  /**
   * @type title: button title
   * @type type: primary or outline
   * @type onClick: onClick function handler
   */

  title: string;
  type: "primary" | "outline" | string;
  disabled?: boolean;
  onClick: MouseEventHandler;
  className?: string;
};

export const Button: FC<ButtonProps> = ({
  title,
  type,
  disabled,
  onClick,
  className,
}) => (
  <button
    className={`${ButtonStyle.button} ${ButtonStyle[type]} ${className}`}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {title}
  </button>
);

Button.defaultProps = {
  title: "",
  type: "primary",
  onClick: () => null,
};
