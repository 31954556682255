import { useMediaQuery, Box, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useTranslation } from "next-i18next";

type PointerProps = {
  id: number;
  size: "small" | "big";
  positionValues?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
  selectedItem: number;
  setSelectedItem: React.Dispatch<React.SetStateAction<number>>;
  title?: string;
  description?: string;
  selectedTheme?: { primary: string; secondary: string; disabled: string };
};
export const Pointer = ({
  id,
  size,
  positionValues,
  selectedItem,
  setSelectedItem,
  title,
  description,
  selectedTheme,
}: PointerProps) => {
  const theme: Theme = useTheme();
  const { t, i18n } = useTranslation();
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));

  const isThisItemSelected = id === selectedItem;

  const sizes = {
    small: {
      normal: 8,
      selected: 16,
      selectedWrapper: 32,
    },
    big: {
      normal: 16,
      selected: 24,
      selectedWrapper: 48,
    },
  };

  const selectedSize = sizes[size];

  const Content = () => (
    <Box width="100%">
      <Typography
        component="h5"
        variant="desktopH5"
        color={
          isThisItemSelected ? selectedTheme?.primary : selectedTheme?.disabled
        }
        mb={1}
      >
        {!!title && t(title)}
      </Typography>
      <Typography
        component="p"
        variant="desktopCaption"
        color={selectedTheme?.secondary}
      >
        {!!description && t(description)}
      </Typography>
    </Box>
  );

  return (
    <Box
      onMouseEnter={() => setSelectedItem(id)}
      display="flex"
      alignItems="center"
      position={size === "big" ? "absolute" : "unset"}
      mb={size === "big" ? 0 : 4}
      {...positionValues}
    >
      <Box width="64px" mt={size === "big" ? 0 : -2}>
        {isThisItemSelected ? (
          <Box
            width={selectedSize.selectedWrapper}
            height={selectedSize.selectedWrapper}
            bgcolor="rgba(52, 41, 41, 0.3)"
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="auto"
          >
            <Box
              width={selectedSize.selected}
              height={selectedSize.selected}
              bgcolor={theme.palette.background.white}
              borderRadius="50%"
            />
          </Box>
        ) : (
          <Box
            width={selectedSize.normal}
            height={selectedSize.normal}
            bgcolor={theme.palette.background.white}
            borderRadius="50%"
            margin="auto"
            boxShadow="0px 1px 3px rgba(0, 0, 0, 0.03), 0px 5px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.05)"
          />
        )}
      </Box>
      {!!title && !!description && <Content />}
    </Box>
  );
};
