import { Box, Typography } from "@mui/material";
import Image from "next/image";
import { FC } from "react";

type FeatureProps = {
  icon: string;
  desc: string;
  width: number;
  height: number;
};

const Feature: FC<FeatureProps> = ({ icon, desc, width, height }) => (
  <Box
    sx={{
      width: "100%",
      maxWidth: 140,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 3,
      textAlign: "center",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "primary.main",
        width: 72,
        height: 72,
        aspectRatio: "1/1",
      }}
    >
      <Image
        src={icon}
        alt={desc}
        width={72}
        height={72}
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
    </Box>
    <Typography variant="desktopBody2" component="p">
      {desc}
    </Typography>
  </Box>
);

export default Feature;
