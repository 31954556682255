import { SVGProps, memo } from "react";

const SvgMastercardLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={24} rx={2} fill="#FBFAF8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.807 21C34.884 21 39 16.97 39 12s-4.116-9-9.193-9c-5.078 0-9.194 4.03-9.194 9s4.116 9 9.194 9Z"
      fill="#F79E1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.194 21c5.077 0 9.193-4.03 9.193-9s-4.116-9-9.194-9C13.117 3 9 7.03 9 12s4.116 9 9.194 9Z"
      fill="#EB001B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 5.022c-2.067 1.65-3.387 4.163-3.387 6.978 0 2.815 1.32 5.328 3.387 6.978 2.067-1.65 3.387-4.163 3.387-6.978 0-2.815-1.32-5.328-3.387-6.978Z"
      fill="#FF5F00"
    />
  </svg>
);

const Memo = memo(SvgMastercardLight);
export default Memo;
