import { LogOut01 } from "@beije/react-icons";
import { Divider, List, ListItem, Typography } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { FC } from "react";

import { MenuItems } from "src/constant";

import { DividerSx, ListItemDividerSx, ListItemSx, ListSx } from "./styles";

type Props = {
  menuItems: MenuItems;
};

const DesktopMenu: FC<Props> = ({ menuItems }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const isActive = (route: string): boolean => route === router.route;

  return (
    <List dense sx={ListSx}>
      {menuItems.map((item, index) => {
        if (item.isNested) return null;
        if (item.name === "divider") {
          return <Divider sx={DividerSx} key={index} />;
        }

        return (
          <Link href={item.route} key={item.name}>
            <ListItem key={index} sx={ListItemSx}>
              <Divider
                orientation="vertical"
                sx={{
                  ...ListItemDividerSx,
                  display: isActive(item.route) ? "block" : "none",
                }}
              />
              {item.icon && <item.icon width={18} height={18} />}
              <Typography
                component="p"
                variant="desktopBody1"
                sx={{
                  fontWeight: isActive(item.route) ? "bold" : "normal",
                }}
              >
                {t(`panel:${item.name}`)}
              </Typography>
            </ListItem>
          </Link>
        );
      })}

      <Link href="/auth/logout?redirect=/">
        <ListItem
          sx={{
            paddingTop: 1.5,
            paddingLeft: 1.5,
            paddingRight: 2,
            paddingBottom: 1.5,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <LogOut01 width={18} height={18} />
          <Typography
            component="p"
            variant="desktopBody1"
            sx={{
              cursor: "pointer",
              fontWeight: "normal",
            }}
          >
            {t(`panel:log-out`)}
          </Typography>
        </ListItem>
      </Link>
    </List>
  );
};

export default DesktopMenu;
