import { Box, Typography } from "@mui/material";
import Slider, { SliderProps } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

// The custom style for BeijeSlider
const BeijeSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  "& .MuiSlider-thumb": {
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:active": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 0 0 10px rgba(52, 49, 49, 0.16) !important",
    },
  },
}));

const RangeInput = (props: SliderProps) => (
  <Box>
    <Box width="100%" maxHeight="66px">
      <BeijeSlider
        {...props}
        marks
        value={props.value}
        valueLabelDisplay={"auto"}
      />
    </Box>

    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Typography variant="desktopBody2">0</Typography>
      <Typography variant="desktopBody2">{props.max}</Typography>
    </Box>
  </Box>
);

export default RangeInput;
