import { SVGProps, memo } from "react";

const SvgVisaLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={24} rx={2} fill="#FBFAF8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.167 7.448A7.545 7.545 0 0 0 29.555 7c-2.88 0-4.909 1.446-4.926 3.516-.016 1.533 1.448 2.386 2.554 2.896 1.134.522 1.516.855 1.51 1.321-.007.715-.905 1.041-1.743 1.041-1.167 0-1.786-.16-2.743-.559l-.376-.17-.41 2.386c.682.297 1.94.556 3.247.569 3.064 0 5.054-1.429 5.076-3.64.01-1.213-.766-2.136-2.447-2.896-1.02-.493-1.643-.822-1.636-1.321 0-.442.527-.917 1.668-.917a5.378 5.378 0 0 1 2.182.409l.262.122.394-2.309ZM18.683 17.842l1.823-10.656h2.914l-1.823 10.656h-2.914ZM37.39 7.196h2.252l2.359 10.649h-2.705s-.267-1.224-.354-1.597l-2.052-.002h-.012l-1.669-.002c-.113.288-.613 1.6-.613 1.6h-3.061l4.328-9.764c.307-.695.829-.884 1.527-.884Zm-1.343 6.868c.242-.614 1.162-2.979 1.162-2.979-.009.014.048-.133.125-.333.082-.21.186-.48.26-.684l.198.92.675 3.076h-2.42Zm-22.661.396 2.853-7.266h3.087l-4.588 10.635-3.085.004-2.608-9.32c1.85.923 3.505 2.768 4.036 4.47l.305 1.477Z"
      fill="#1A1F71"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.737 7.187h-4.7L6 7.408c3.657.883 6.076 3.014 7.08 5.575l-1.022-4.897c-.176-.675-.688-.876-1.32-.9"
      fill="#F9A51A"
    />
  </svg>
);

const Memo = memo(SvgVisaLight);
export default Memo;
