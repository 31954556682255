export const ListSx = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 0,
  width: "100%",
  paddingTop: 0,
  paddingBottom: 0,
};

export const DividerSx = {
  marginY: 3,
  height: 1,
  width: "100%",
};

export const ListItemSx = {
  paddingTop: 1.5,
  paddingRight: 2,
  paddingBottom: 1.5,
  paddingLeft: 0,
  display: "flex",
  alignItems: "center",
  flex: "none",
  flexShrink: 1,
  flexGrow: 0,
  gap: 2,
  cursor: "pointer",
};

export const ListItemDividerSx = {
  borderRightWidth: 2,
  height: 26,
  borderColor: "primary.main",
};
